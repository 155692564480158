import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { Button } from "react-bootstrap";
import Bio from "./bio.js";
import isAuthed from "../../../api/utils/isAuthed";
import {
  getExpert,
  getAnswersByExpert,
  followExpert,
  unfollowExpert,
} from "../../../api/actions/experts";
import Expertmedia from "../assets/images/expertImg.png";
import Post from "../../Questions/questionPost/post.js";
import diet from "../../Questions/assets/images/diet.png";
import post1 from "../../Questions/assets/images/post1.png";
import post2 from "../../Questions/assets/images/post2.png";
import "./assets/css/style.scss";

export default function ProfilePage({ location }) {
  const [expert, setExpert] = useState();
  const [expertAnswers, setExpertAnswers] = useState();
  useEffect(() => {
    const expertId = queryString.parse(location.search).id;

    async function fetchExpertAndAnswers() {
      let response = await getExpert(expertId);
      setExpert(response.data);

      if (isAuthed()) {
        response = await getAnswersByExpert(expertId);
        setExpertAnswers(response.data.answers);
        setExpert((expert) => ({
          ...expert,
          answers_count: response.data.answers_count,
        }));
      }
    }
    if (expertId) fetchExpertAndAnswers();
  }, [location.search]);

  if (!expert) return null;

  let nameWithDegrees = `${expert.first_name} ${expert.last_name}`;
  if (expert.degrees.length)
    nameWithDegrees += expert.degrees
      .map(({ description }) => description)
      .join(", ");

  async function handleToggleFollow(shouldFollow, expertId) {
    const response = shouldFollow
      ? await followExpert(expertId)
      : await unfollowExpert(expertId);

    if (response && response.status === 200) {
      setExpert((expert) => ({ ...expert, follower: shouldFollow }));
    }
  }

  return (
    <>
      <div className="box-grey-mobile-expert"></div>
      <div className="Profile experts qustionsPage">
        <Bio content={expert} onToggleFollow={handleToggleFollow} />
        {expertAnswers && (
          <div className="content">
            <h2 className="nametitile">{expert.first_name}’s Answers</h2>
            {console.log(expertAnswers)}
            {expertAnswers.map((answer) => (
              <div key={answer.id}>
                <Post
                  title={answer.question.description}
                  img={expert.url_avatar}
                  name={nameWithDegrees}
                  designation={answer.question.subtopics
                    .map(({ description }) => description)
                    .join(", ")}
                  answer={answer.description}
                  thumbnail={post2}
                  tag={diet}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
