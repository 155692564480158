import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import isAuthed from "../../../api/utils/isAuthed";
import Connect from "../connect";
import like from "../assets/images/like.svg";
import answered from "../assets/images/message.svg";
import education from "../assets/images/education.svg";
import plus from "../assets/images/plus.svg";
import brief from "../assets/images/briefcase.svg";
export default function Bio({ content: expert, onToggleFollow }) {
  const [choosebox, setchoosebox] = useState(false);
  const [expertData, setExpert] = useState({});

  const ExpertActions = ({ expert }) => (
    <Button
      variant="blue"
      href=""
      onClick={() => {
        setchoosebox(true);
        setExpert(expert);
      }}
      className="connect"
    >
      Connect
    </Button>
  );

  return (
    <>
      <div className="Bio seocheck">
        <article className="FeaturedExpert">
          <div className="content">
            <div className="box">
              <Row>
                <Col md={12} lg={4}>
                  <img src={expert.url_avatar} className="profilepic" />
                  <div className="counts d-none d-lg-block pt-3">
                    {expert.answers_count != null && (
                      <div className="answered">
                        <img src={answered} />
                        <span>
                          <strong>{expert.answers_count}</strong> Questions
                          Answered
                        </span>
                      </div>
                    )}
                    {/* <div className="thankx">
                    <img src={like} />
                    <span>
                      <strong>{x.PatientThankxCount}</strong> Patients said Thanks
                    </span>
                  </div> */}
                  </div>
                </Col>
                <Col md={12} lg={8}>
                  <div className="details">
                    <div className="details-top">
                      <div className="details-top-content">
                        <div className="box_sched">
                          <h1>
                            {expert.first_name} {expert.last_name}
                            {expert.degrees.length > 0 &&
                              `, ${expert.degrees
                                .map(({ description }) => description)
                                .join(", ")}`}
                          </h1>
                        </div>
                        <div className="location">
                          <h3>
                            {" "}
                            {expert.city}, {expert.country}
                          </h3>
                        </div>
                        <div className="expert-specialities">
                          <img src={plus} />
                          <h3>
                            {" "}
                            {expert.specialities
                              .map(({ description }) => description)
                              .join(", ")}
                          </h3>
                        </div>
                        <div className="expert-affiliation">
                          <img src={brief} />
                          <h2> {expert.affiliation}</h2>
                        </div>
                      </div>
                    </div>

                    <div className="counts d-lg-none">
                      {expert.answers_count != null && (
                        <div className="answered">
                          <span>
                            <strong>{expert.answers_count}</strong>
                          </span>
                          <img src={answered} />
                        </div>
                      )}
                      {/* <div className="thankx">
                      <span>
                        <strong>{x.PatientThankxCount}</strong>
                      </span>
                      <img src={like} />
                    </div> */}
                    </div>

                    {/*<div className="social">
                    <Button
                      variant="blue-light"
                      active={expert.follower}
                      disabled={!isAuthed()}
                      onClick={() =>
                        onToggleFollow(!expert.follower, expert.id)
                      }
                      className="follow"
                    >
                      {expert.follower ? "Unfollow" : "Follow"}
                    </Button>
                    <Button variant="blue" href="" className="connect">
                      Connect
                    </Button>
                    </div> */}
                    <div className="featured_social">
                      <ExpertActions expert={expert} />
                    </div>
                    <p>{expert.about_me}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </article>
        <Connect
          status={choosebox}
          setchoosebox={setchoosebox}
          expertData={expert}
        />
      </div>
    </>
  );
}
